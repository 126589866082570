import type { Ref, MaybeRefOrGetter } from 'vue'
import type { IManagerFormNodeValue, INodeField } from '@manager'

export const useFieldData = (
  node: INodeField,
  parentValue: Ref<IManagerFormNodeValue>,
  value?: MaybeRefOrGetter,
) => {
  const registerData = () => {
    if (`data_${node.name}` in parentValue.value) return
    Object.defineProperty(parentValue.value, `data_${node.name}`, {
      enumerable: false,
      writable: true,
      configurable: true,
    })
    parentValue.value[`data_${node.name}`] = computed(() =>
      toValue(value ?? parentValue.value[node.name]),
    )
  }

  return { registerData }
}
